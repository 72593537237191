import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import ReactGA from 'react-ga4';
import Home from './pages/Home';
import Address from './pages/Address';
import Navbar from './components/Navbar';

// Initialize GA4
ReactGA.initialize('G-KQXFKL8FBX');

// Analytics tracking component
function PageTracking() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname });
  }, [location]);

  return null;
}

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Navbar />
        <PageTracking />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/addresses" element={<Address />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
