import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Link,
  Heading,
  Tag,
  Spinner,
} from '@chakra-ui/react';
import addressTags from '../data/address_tags.json';
import Footer from '../components/Footer';

const Address = () => {
  const [addresses, setAddresses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [circulatingSupply, setCirculatingSupply] = useState(0);

  const truncateAddress = (address) => {
    return address.replace(/(.{16}).*(.{16})/, '$1...$2');
  };

  const formatCKB = (balance) => {
    return (Number(balance) / 100000000).toFixed(2);
  };

  const getAddressTag = (address) => {
    const found = addressTags.addresses.find(item => item.address === address);
    return found ? found.tag : '';
  };

  const calculatePercentage = (balance) => {
    if (!circulatingSupply) return 0;
    return ((parseFloat(balance) / circulatingSupply) * 100).toFixed(2);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [addressesResponse, statsResponse] = await Promise.all([
          fetch(
            'https://api-explorer.nervosscan.com/api/v1/statistics/address_balance_ranking',
            {
              headers: {
                Accept: 'application/vnd.api+json',
                'Content-Type': 'application/vnd.api+json',
              },
            }
          ),
          fetch('https://api-explorer.nervosscan.com/api/v1/daily_statistics/circulating_supply-burnt-locked_capacity',           {
            headers: {
              Accept: 'application/vnd.api+json',
              'Content-Type': 'application/vnd.api+json',
            },
          }),
        ]);

        const addressesData = await addressesResponse.json();
        const statsData = await statsResponse.json();

        // Get the latest circulating supply from the last item in the array
        const latestStats = statsData.data[statsData.data.length - 1];
        const supply = parseFloat(latestStats.attributes.circulating_supply);
        setCirculatingSupply(supply);

        const addressList = addressesData.data.attributes.address_balance_ranking.map(address => ({
          ...address,
          tag: getAddressTag(address.address)
        }));

        setAddresses(addressList);
        setError(null);
      } catch (err) {
        setError('Failed to load data');
        console.error('Error fetching data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // const interval = setInterval(fetchData, 30000);
    // return () => clearInterval(interval);
  }, []);

  if (error) {
    return (
      <Container maxW="7xl" py={8}>
        <Box color="red.500">{error}</Box>
      </Container>
    );
  }

  return (
    <Box minH="100vh">
      <Box bg="green.400" pb={8}>
        <Container maxW="7xl">
          <Heading
            as="h1"
            size="2xl"
            color="white"
            textAlign="center"
            fontWeight="bold"
            pt={8}
            mb={8}
          >
            Welcome to Nervos Mirana explorer
          </Heading>
        </Container>
      </Box>

      <Box bg="white" py={8}>
        <Container maxW="7xl">
        <Heading as="h4" size="lg" mb={6}>
            Address Ranking
          </Heading>
          <Box overflow="hidden" bg="white" shadow="md" rounded="lg">
            <Table variant="simple">
              <Thead bg="gray.50">
                <Tr>
                  <Th>Ranking</Th>
                  <Th>Address</Th>
                  <Th>Tag</Th>
                  <Th isNumeric>Balance (CKB)</Th>
                  <Th isNumeric>Percentage</Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <Tr>
                    <Td colSpan={5} textAlign="center" py={4}>
                      <Spinner size="xl" />
                    </Td>
                  </Tr>
                ) : (
                  addresses.map((address) => (
                    <Tr key={address.address}>
                      <Td>{address.ranking}</Td>
                      <Td maxW="300px" isTruncated>
                        <Link
                          href={`https://explorer.nervos.org/address/${address.address}`}
                          isExternal
                          target="_blank"
                          rel="noopener noreferrer"
                          color="blue.500"
                          _hover={{ textDecoration: 'underline' }}
                        >
                          {truncateAddress(address.address)}
                        </Link>
                      </Td>
                      <Td>
                        {address.tag && (
                          <Tag size="sm" ml={2} colorScheme="green">
                            {address.tag}
                          </Tag>
                        )}
                      </Td>
                      <Td isNumeric>{formatCKB(address.balance)} CKB</Td>
                      <Td isNumeric>{calculatePercentage(address.balance)}%</Td>
                    </Tr>
                  ))
                )}
              </Tbody>
            </Table>
          </Box>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Address;
