import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Box, Heading, VStack } from '@chakra-ui/react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet.fullscreen/Control.FullScreen.css';
import 'leaflet.fullscreen';

const NodeStatus = () => {
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const heatmapLayerRef = useRef(null);
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  const initializeMap = () => {
    if (!mapRef.current || mapInstanceRef.current) return;

    try {
      const map = L.map(mapRef.current, {
        maxBounds: [[-90, -180], [90, 180]],
        minZoom: 2,
        maxZoom: 18
      }).setView([0, 0], 2);

      L.tileLayer('https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png', {
        minZoom: 2,
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map);

      // 移除所有现有的控件
      document.querySelectorAll('.leaflet-control-zoom,.leaflet-control-zoom-fullscreen,.move-control')
        .forEach(target => target.remove());
      document.querySelector(".leaflet-heatmap-layer")?.remove();

      // 重新添加控件
      map.removeControl(map.zoomControl);
      map.addControl(L.control.zoom({ position: 'bottomright' }));
      map.addControl(L.control.fullscreen({ position: 'topright' }));

      mapInstanceRef.current = map;
    } catch (err) {
      console.error('Error initializing map:', err);
    }
  };

  const loadHeatmapScripts = async () => {
    try {
      // 加载主要的 heatmap.js 脚本
      await new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/heatmap.js/2.0.2/heatmap.min.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });

      // 加载 Leaflet 热力图插件
      await new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet.heat/0.2.0/leaflet-heat.js';
        script.async = true;
        script.onload = resolve;
        script.onerror = reject;
        document.head.appendChild(script);
      });

      setScriptsLoaded(true);
    } catch (err) {
      console.error('Error loading heatmap scripts:', err);
    }
  };

  const updateHeatmap = useCallback(async () => {
    if (!scriptsLoaded || !mapInstanceRef.current) return;

    try {
      const response = await fetch('https://api-nodes.ckb.dev/peer?staging=true&unknown_offline_timeout=10080&network=mirana');
      const data = await response.json();

      const heatMapData = data
        .filter(({ latitude, longitude }) => latitude !== null && longitude !== null)
        .map(({ latitude, longitude }) => [latitude, longitude, 5000]);
      // 移除旧的热力图层
      if (heatmapLayerRef.current) {
        mapInstanceRef.current.removeLayer(heatmapLayerRef.current);
      }

      // 创建新的热力图层
      heatmapLayerRef.current = L.heatLayer(heatMapData, {
        radius: 15
      }).addTo(mapInstanceRef.current);

    } catch (err) {
      console.error('Error updating heatmap:', err);
    }
  }, [scriptsLoaded]);

  // 初始化地图
  useEffect(() => {
    initializeMap();
    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
    };
  }, []);

  // 加载脚本
  useEffect(() => {
    loadHeatmapScripts();
  }, []);

  // 更新热力图数据
  useEffect(() => {
    if (scriptsLoaded) {
      updateHeatmap();
      // const interval = setInterval(updateHeatmap, 30000);
      // return () => clearInterval(interval);
    }
  }, [scriptsLoaded, updateHeatmap]);

  return (
    <VStack spacing={6} align="stretch" w="100%">
      <Box bg="white" shadow="md" borderRadius="lg" p={6} w="100%">
        <Heading size="lg" mb={4}>Global Node Distribution</Heading>
        <Box
          ref={mapRef}
          h="500px"
          w="100%"
          borderRadius="md"
          overflow="hidden"
          position="relative"
          id="node-map"
        />
      </Box>
    </VStack>
  );
};

export default NodeStatus;
