import React from 'react';
// import { useNavigate } from 'react-router-dom';
import {
  Container,
  VStack,
  Box,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
// import { SearchIcon } from '@chakra-ui/icons';
import Statistics from '../components/Statistics';
import LatestBlocks from '../components/LatestBlocks';
import LatestTransactions from '../components/LatestTransactions';
import NodeStatus from '../components/NodeStatus';
import Footer from '../components/Footer';

const Home = () => {
  // const [searchValue] = useState('');
  // const navigate = useNavigate();

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   if (searchValue.trim()) {
  //     navigate(`/search/${searchValue}`);
  //   }
  // };

  return (
    <Box>
      {/* Hero Section with Search */}
      <Box bg="green.400" pb={16} pt={8}>
        <Container maxW="7xl">
          <VStack spacing={8} align="center">
            <Heading
              as="h1"
              size="2xl"
              color="white"
              textAlign="center"
              fontWeight="bold"
            >
              Welcome to Nervos Mirana explorer
            </Heading>

            <Box w="full" maxW="3xl">
              {/* <form onSubmit={handleSearch}>
                <InputGroup size="lg">
                  <InputLeftElement pointerEvents="none">
                    <SearchIcon color="gray.400" />
                  </InputLeftElement>
                  <Input
                    bg="white"
                    placeholder="Search by Address / Transaction ID / Block ID"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    borderRadius="md"
                    _placeholder={{ color: 'gray.500' }}
                  />
                  <Button
                    ml={2}
                    colorScheme="blue"
                    type="submit"
                    size="lg"
                  >
                    Search
                  </Button>
                </InputGroup>
              </form> */}
            </Box>
          </VStack>
        </Container>
      </Box>

      {/* Content Section */}
      <Box mt={-8}>
        <Container maxW="7xl">
          <VStack spacing={6} align="stretch">
            <Statistics />

            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={6}>
              <GridItem>
                <LatestBlocks />
              </GridItem>
              <GridItem>
                <LatestTransactions />
              </GridItem>
            </Grid>

            <NodeStatus />
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};

export default Home;
